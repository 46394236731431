import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import StepCard from "../components/StepCard";
import {
  EnergISyncTestingSteps,
  WifiHealthStatus,
  TestingType,
  AmplifyConfig,
  PubSubConfig,
  MQTTEvents,
  EnergISyncTestingConfig,
  EnergISyncParameters,
  EnergISyncFailedReasons,
  EnergISyncFailedReasonsText,
} from "../config/constants";
import Images from "../config/images";
import {
  getNumberOfPhases,
  showErrorMessage,
} from "../utils/helpers";

import Summary from "./EnergISyncTestScreens/Summary";
import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils'
import { PubSub } from "@aws-amplify/pubsub";
import SetupTesting from "./EnergISyncTestScreens/Setup";
import APIs from "../utils/apis";
import MeterTest from "./EnergISyncTestScreens/MeterTest";
import TestingActions from "../redux/actions/testing";

class TestDevice extends Component {
  state = {
    step: EnergISyncTestingSteps.Setup,
    devices: [],
    connectionStatus: {
      deviceId: "",
      connectionId: "",
      connected: false,
      numOfConnections: 0,
      strength: 0,
      ws: "",
      wp: "",
      healthy: true,
      wifiHealth: WifiHealthStatus.Unknown,
    },
    progress: {},
    testingStatus: {}
  };

  testerConnections = {};
  sentMessages = [];
  testStartTime = new Date();
  subscriptions = {};
  testingStarted = {};

  componentDidMount() {
    Hub.listen("pubsub", (data) => {
      const { payload } = data;
      if (payload.event === "ConnectionStateChange") {
        if (payload.data.connectionState === "Connected") {
          const testingStationDeviceId = payload.data.provider._config.clientId.split('_')[0];
          this.onTestingStationConnected(testingStationDeviceId);
        }
      }
    });
  }

  deviceStatus = (deviceId) => {
    let passed = true;
    let failedReason = EnergISyncFailedReasons.NotApplicable;
    let failedReading = {};
    for (const message of this.sentMessages.filter(d => d.deviceId === deviceId)) {
      if (!message?.device_readings) {
        passed = false;
        failedReason = EnergISyncFailedReasons.DeviceReadingsNotFound
        failedReading = { ...message };
        break;
      } else if (!message?.tester_readings) {
        passed = false;
        failedReason = EnergISyncFailedReasons.TesterReadingsNotFound;
        failedReading = { ...message };
        break;
      } else {
        let range = EnergISyncTestingConfig.Range[message.command];
        const { device_readings, tester_readings } = message;
        if (!(device_readings.p >= range.minimumWattage && device_readings.p <= range.maximumWattage)) {
          passed = false;
          failedReason = EnergISyncFailedReasons.ReadingsOutOfRange;
          failedReading = { ...message };
          break;
        }
        if (!(tester_readings.p >= range.minimumWattage && tester_readings.p <= range.maximumWattage)) {
          passed = false;
          failedReason = EnergISyncFailedReasons.TesterReadingsOutOfRnage;
          failedReading = { ...message };
          break;
        }
        for (const param of Object.values(EnergISyncParameters)) {
          let reading = device_readings[param];
          let expected = tester_readings[param];
          let diff = parseInt(Math.abs(reading - expected))
          if (param !== EnergISyncParameters.POWER_FACTOR) {
            if (diff < 1) {
              diff = 0;
            }
          }
          let error = diff * 100 / expected;
          if (error > EnergISyncTestingConfig.Tolerance[param]) {
            passed = false;
            failedReason = EnergISyncFailedReasons.InaccurateReading[param];
            failedReading = { ...message };
            break;
          }
        }
      }
    }   
    return { passed, failedReason, failedReading }
  }

  render() {
    const {
      step,
      connectionStatus,
      devices,
      progress,
      testingStatus,
    } = this.state;
    return (
      <div>
        <Row className="g-0">
          <Col lg={3}>
            <div className="section_container">
              <StepCard
                label={"Register Device"}
                step={EnergISyncTestingSteps.Setup}
                setStep={this.setStep}
                icon={Images.EnergISyncHardwareTesting.Setup}
                selected={step === EnergISyncTestingSteps.Setup}
              />
              <StepCard
                label={"Meter Test"}
                step={EnergISyncTestingSteps.Meter}
                setStep={this.setStep}
                icon={Images.EnergISyncHardwareTesting.Meter}
                selected={step === EnergISyncTestingSteps.Meter}
              />
              <StepCard
                label={"Summary"}
                step={EnergISyncTestingSteps.Summary}
                setStep={this.setStep}
                icon={Images.EnergISyncHardwareTesting.Summary}
                selected={step === EnergISyncTestingSteps.Summary}
              />
            </div>
          </Col>
          <Col
            lg={9}
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <div className="section_container workAreaDiv invisibleScrollbar">
              {step === EnergISyncTestingSteps.Setup && (
                <SetupTesting
                  devices={devices}
                  onStepComplete={this.onSetupDone}
                  addDevice={this.addDevice}
                  syncStatus={this.syncStatus}
                  connectionStatus={connectionStatus}
                  removeDevice={this.removeDevice}
                />
              )}
              {step === EnergISyncTestingSteps.Meter && (
                <MeterTest
                  devices={devices}
                  progress={progress}
                  testingStatus={testingStatus}
                  onStepComplete={this.onDetailsNext}
                  connectionStatus={connectionStatus}
                />
              )}
              {step === EnergISyncTestingSteps.Summary && (
                <Summary
                  testingStatus={testingStatus}
                  devices={devices}
                  sentMessages={this.sentMessages}
                  onSubmit={this.onSubmit}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  onSetupDone = async () => {
    // check if all added devices and testing meters are connected to the server
    if (this.state.devices.length === 0) {
      return showErrorMessage('Can not start test', 'Add devices to test');
    }
    for (const device of this.state.devices) {
      if (!this.state.connectionStatus[device.deviceId]?.connected) {
        return showErrorMessage('Can not start test', 'All devices should be online')
      }
      if (!this.state.connectionStatus[device.testingStation.energisync_tester]?.connected) {
        return showErrorMessage('Can not start test', 'All devices should be online')
      }
    }
    this.setStep(EnergISyncTestingSteps.Meter)
    let promises = []
    for (let i = 0; i < this.state.devices.length; i += 1) {
      this.testingStarted[this.state.devices[i].deviceId] = true;
      for (let switchId = 0; switchId < getNumberOfPhases(this.state.devices[i].deviceId); switchId += 1) {
        promises.push(this.testDevice({ ...this.state.devices[i], switchId }))
      }
    }
    await Promise.all(promises);
    this.autoSubmitPassedDevices();
    this.setStep(EnergISyncTestingSteps.Summary);
    // start the test
  };

  controlEnergISync = async (details = { deviceId: '', switchId: '', command: '', id: '' }) => {
    const { deviceId, switchId, command , id} = details;
    const device = this.state.devices.find(d => String(d.deviceId) === deviceId);
    const { testingStation } = device;
    return this.mqttPublish(`${MQTTEvents.ENERGISYNC_COMMAND}/${deviceId}`, {
      deviceId,
      switchId,
      command,
      id,
      "controllerId": this.props.user._id,
      "controllerType": "testingStation",
      "control": true
    }, testingStation.deviceId)
  }

  testDevice = async (device) => {
    const { deviceId, switchId, testingStation } = device;
    this.setState({ progress: { ...this.state.progress, [`${deviceId}_${switchId}`]: 0 } })
    for (let i = 0; i < EnergISyncTestingConfig.NumberOfCycles; i += 1) {
      if (!this.state.testingStatus[deviceId]?.passed) {
        continue;
      }
      // loop through the number of commands
      // first send an on command
      let command = 100;
      let commandId = `${new Date().getTime().toString(32)}.${parseInt(Math.random()*10)}`;
      let requestId = `${new Date().getTime().toString(32)}.${parseInt(Math.random()*10)}`;
      this.sentMessages = this.sentMessages.concat({
        command,
        commandTimestamp: new Date(),
        commandId,
        requestId,
        deviceId,
        switchId,
        testerId: testingStation.energisync_tester,
      });
      await this.controlEnergISync({ deviceId, switchId, command, id: commandId });
      let progress = ((i * 4 + 1) * 100 / 4) / EnergISyncTestingConfig.NumberOfCycles;
      this.setState({
        progress: {
          ...this.state.progress,
          [`${deviceId}_${switchId}`]: progress
        }
      })
      await sleep(EnergISyncTestingConfig.ReadingsDelay)
      await this.mqttPublish(`${MQTTEvents.ENERGISYNC_GET_READINGS}/${deviceId}`, requestId, testingStation.deviceId);
      await this.mqttPublish(`${MQTTEvents.ENERGISYNC_GET_READINGS}/${testingStation.energisync_tester}`, requestId, testingStation.deviceId);
      progress = ((i * 4 + 2) * 100 / 4) / EnergISyncTestingConfig.NumberOfCycles
      this.setState({
        progress: {
          ...this.state.progress,
          [`${deviceId}_${switchId}`]: progress
        }
      })
      await sleep(EnergISyncTestingConfig.CommandsDelay);
      // send an off command

      command = 0;
      commandId = `${new Date().getTime().toString(32)}.${parseInt(Math.random()*10)}`;
      requestId = `${new Date().getTime().toString(32)}.${parseInt(Math.random()*10)}`;
      this.sentMessages = this.sentMessages.concat({
        command,
        commandTimestamp: new Date(),
        commandId,
        requestId,
        deviceId,
        switchId,
        testerId: testingStation.energisync_tester,
      });
      await this.controlEnergISync({ deviceId, switchId, command, id: commandId });
      progress = ((i * 4 + 3) * 100 / 4) / EnergISyncTestingConfig.NumberOfCycles
      this.setState({
        progress: {
          ...this.state.progress,
          [`${deviceId}_${switchId}`]: progress
        }
      })
      await sleep(EnergISyncTestingConfig.ReadingsDelay)
      await this.mqttPublish(`${MQTTEvents.ENERGISYNC_GET_READINGS}/${deviceId}`, requestId, testingStation.deviceId);
      await this.mqttPublish(`${MQTTEvents.ENERGISYNC_GET_READINGS}/${testingStation.energisync_tester}`, requestId, testingStation.deviceId);
      progress = ((i * 4 + 4) * 100 / 4) / EnergISyncTestingConfig.NumberOfCycles
      this.setState({
        progress: {
          ...this.state.progress,
          [`${deviceId}_${switchId}`]: progress,
        }
      })
      await sleep(EnergISyncTestingConfig.CommandsDelay);

      // repeat the loop
    }
    await sleep(EnergISyncTestingConfig.ReadingsDelay);
    this.testingStarted[`${deviceId}_${switchId}`] = false;
    this.setState({ testingStatus: { ...this.state.testingStatus, [deviceId]: this.deviceStatus(deviceId) } })
  }

  addDevice = async (data) => {
    // check if the device exists if not show error
    // subscribe to events of the new testing station meter and the new device
    if (!data.deviceId) {
      return showErrorMessage('Can not add', 'Enter a device id');
    }
    if (this.state.devices.some(d => d.deviceId === data.deviceId)) {
      return showErrorMessage('Can not add', 'Device is already added for testing');
    }
    if (!data.testingStation) {
      return showErrorMessage('Can not add', 'Select a testing station');
    }
    if (getNumberOfPhases(data.deviceId) > getNumberOfPhases(data.testingStation.energisync_tester)) {
      return showErrorMessage('Can not add', 'Tester meter should have equal or more phases than device being tested');
    }
    const testingHistory = await TestingActions.details(data.deviceId);
    const { device } = testingHistory.data;
    if (!testingHistory.success) {
      // if testing history fetch fails
      return showErrorMessage('Can not add', `Could not get testing history\n${testingHistory.message}`);
    }
    if (!device || device.basic_tested !== true) {
      // device does not exist or the device basic testing is not done
      return showErrorMessage('Can not add', 'Device should pass basic testing first');
    }
    if (device.testing_history.some(log => log.resolved !== true)) {
      return showErrorMessage('Can not add', 'All problems should be resolved first');
    }
    const houseDetails = await TestingActions.deviceHouseDetails(data.deviceId);
    if (!houseDetails.success) {
      // house details fetching failed
      return showErrorMessage('Can not add', `Could not get house details\n${houseDetails.message}`);
    }
    if (houseDetails.data.house !== null) {
      // device is added in house, can not be tested
      return showErrorMessage('Can not add', `Device is added in house`);
    }
    this.connectTestingStation(data.testingStation, data.deviceId);
    let progress = { ...this.state.progress };
    for (let switchId = 0; switchId < getNumberOfPhases(data.deviceId); switchId += 1) {
      progress[`${data.deviceId}_${switchId}`] = 0;
    }
    this.setState({
      devices: this.state.devices.concat(data),
      testingStatus: {
        ...this.state.testingStatus,
        [data.deviceId]: { passed: true, failedReason: EnergISyncFailedReasons.NotApplicable }
      },
      progress,
    })
  }

  removeDevice = async (details = { deviceId: '' }) => {
    // unsubscribe to events of the device and testing station meters
    const { deviceId } = details;
    const device = this.state.devices.find(d => d.deviceId === deviceId);
    const { testingStation } = device;
    let testingStatus = { ...this.state.testingStatus };
    let progress = { ...this.state.progress };
    for (let switchId = 0; switchId < getNumberOfPhases(deviceId); switchId += 1) {
      delete progress[`${deviceId}_${switchId}`];
    }
    delete testingStatus[deviceId];
    let connectionStatus = { ...this.state.connectionStatus };
    delete connectionStatus[deviceId];
    delete connectionStatus[testingStation.energisync_tester];
    this.setState({
      devices: this.state.devices.filter(d => d.deviceId !== deviceId),
      connectionStatus,
      testingStatus,
      progress
    })
  }

  autoSubmitPassedDevices = async  () => {
    const { connectionStatus, devices } = this.state;
    const test_end_time = new Date();
    for (const device of devices) {
      const { deviceId, testingStation } = device;
      const testingStatus = this.deviceStatus(deviceId);
      const messages = this.sentMessages.filter(msg => String(msg.deviceId) === String(deviceId));
      if (testingStatus.passed === false) {
        continue;
      }
      const meta_data = {
        test_start_time: this.testStartTime,
        test_end_time: test_end_time,
        connectionStatus: connectionStatus[deviceId],
        testingStatus,
        messages,
      }
      const payload = {
        deviceId,
        testing_station: testingStation.deviceId,
        testing_type: EnergISyncTestingSteps.Summary,
        type: TestingType.Hardware,
        reason: EnergISyncFailedReasonsText[testingStatus.failedReason],
        message: 'OKAY',
        meta_data,
      };
      let result = { success: false, message: 'API not called', data: payload };
      if (testingStatus.passed === true) {
        result = await this.props.passed(payload);
      }
      if (!result.success) {
        showErrorMessage(`Could not pass device - ${deviceId}`, result.message);
      } else {
        this.resetMQTTConnection(testingStation.deviceId);
        let testingStatuses = { ...this.state.testingStatus };
        delete testingStatuses[deviceId];
        this.setState({
          devices: this.state.devices.filter(d => d.deviceId !== String(deviceId)),
          testingStatus: testingStatuses,
        })
      }
    }
    if (this.state.devices.length === 0) {
      this.setStep(EnergISyncTestingSteps.Setup);
    }
  }

  onSubmit = async (message) => {
    const { connectionStatus, devices } = this.state;
    const test_end_time = new Date();
    for (const device of devices) {
      const { deviceId, testingStation } = device;
      const testingStatus = this.deviceStatus(deviceId);
      const messages = this.sentMessages.filter(msg => String(msg.deviceId) === String(deviceId));
      if (testingStatus.passed === false && String(message).length < 10) {
        showErrorMessage(`Can not submit device - ${deviceId}`, `Message is mandatory if device has failed`);
        continue;
      }
      const meta_data = {
        test_start_time: this.testStartTime,
        test_end_time: test_end_time,
        connectionStatus: connectionStatus[deviceId],
        testingStatus,
        messages,
      }
      const payload = {
        deviceId,
        testing_station: testingStation.deviceId,
        testing_type: EnergISyncTestingSteps.Summary,
        type: TestingType.Hardware,
        reason: EnergISyncFailedReasonsText[testingStatus.failedReason],
        message: testingStatus.passed ? 'OKAY' : message,
        meta_data,
      };
      let result = { success: false, message: 'API not called', data: payload };
      if (testingStatus.passed === true) {
        result = await this.props.passed(payload);
      } else {
        result = await this.props.failed(payload);
      }
      if (!result.success) {
        showErrorMessage(`Could not pass device - ${deviceId}`, result.message);
      } else {
        this.resetMQTTConnection(testingStation.deviceId);
        let testingStatuses = { ...this.state.testingStatus };
        delete testingStatuses[deviceId];
        this.setState({
          devices: this.state.devices.filter(d => d.deviceId !== String(deviceId)),
          testingStatus: testingStatuses,
        })
      }
    }
    if (this.state.devices.length === 0) {
      this.setStep(EnergISyncTestingSteps.Setup);
    }
  };

  setStep = (step) => {
    this.setState({ step });
  };


  syncStatus = () => {
    for (const device of this.state.devices) {
      const { deviceId, testingStation } = device;
      this.mqttPublish(
        `${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`,
        {
          deviceIds: [deviceId],
          switchPinTester: testingStation.switch_pin_tester,
          fanModuleTester: testingStation.fan_module_tester,
          energISyncTester: testingStation.energisync_tester,
        },
        testingStation.deviceId,
      );
    }

  }
  /**
   * 
   * @param {Object} testingStation the testing station that we are testing on
   * @param {deviceId} deviceId the device that we are testing
   */
  connectTestingStation = async (testingStation, deviceId) => {
    this.resetMQTTConnection(testingStation.deviceId);
    Amplify.configure(AmplifyConfig);
    const testerConnection = new PubSub({
      endpoint: PubSubConfig.aws_pubsub_endpoint,
      region: PubSubConfig.aws_pubsub_region,
      clientId: `${testingStation.deviceId}_${new Date().getTime().toString(32)}`,
    })
    this.testerConnections = { ...this.testerConnections, [testingStation.deviceId]: testerConnection };
    let s = testerConnection.subscribe({
      topics: [
        `${MQTTEvents.CONNECTED}/${deviceId}`,  // device connection
        `${MQTTEvents.DISCONNECTED}/${deviceId}`,  // device disconnect
        `${MQTTEvents.CONNECTED}/${testingStation.energisync_tester}`,  // tester connect
        `${MQTTEvents.DISCONNECTED}/${testingStation.energisync_tester}`,  // tester disconnect
        `${MQTTEvents.ENERGISYNC_READINGS}/${deviceId}`, // device readings
        `${MQTTEvents.ENERGISYNC_READINGS}/${testingStation.energisync_tester}`, // tester readings 
        `${MQTTEvents.STATUS_SYNC}/${testingStation.deviceId}`, // testing status connection updates from sync request
        `${MQTTEvents.ENERGISYNC_MESSAGE}/${deviceId}`, // update of control messages
      ]
    }).subscribe({
      next: (data) => {
        let topic = String(
          data[Object.getOwnPropertySymbols(data)[0]],
        );

        if (topic.startsWith(MQTTEvents.ENERGISYNC_READINGS)) {
          const readingsDevice = topic.split('/')[3];
          if (readingsDevice === testingStation.energisync_tester) {
            // handle latest reading from energisync tester
            this.sentMessages = this.sentMessages.map((message) => {
              if (String(message.testerId) === String(readingsDevice) && String(message.switchId) === String(data.m) && String(message.requestId) === String(data.id)) {
                // found the request whose response we have recevied
                if (message?.tester_readings?.p === undefined) {
                  return {
                    ...message,
                    tester_readings: data,
                  }
                } else {
                  console.log("Got duplicate update for the same request id ignoring, tester", data, message);
                  return message;
                }
              }
              return message;
            })
          }
          if (readingsDevice === deviceId) {
            // reading from a device being tested
            this.sentMessages = this.sentMessages.map((message) => {
              if (String(message.deviceId) === String(readingsDevice) && String(message.switchId) === String(data.m) && String(message.requestId) === String(data.id)) {
                // found the request whose response we have recevied
                if (message?.device_readings?.p === undefined) {
                  return {
                    ...message,
                    device_readings: data,
                  }
                } else {
                  console.log("Got duplicate update for the same request id ignoring, device", data, message);
                  return message;
                }
              }
              return message;
            })
          }
        } else if (topic.startsWith(MQTTEvents.CONNECTED)) {
          const connectedDevice = topic.split('/')[4];
          let deviceConnectionStatus = this.state.connectionStatus[connectedDevice] || {};
          deviceConnectionStatus = {
            ...deviceConnectionStatus,
            deviceId,
            connected: true,
            numOfConnections: (deviceConnectionStatus?.numOfConnections || 0) + 1,
            sessionId: data.sessionIdentifier,
          }
          if (this.testingStarted[deviceId]) {
            this.setState({ testingStatus: { ...this.state.testingStatus, [deviceId]: { passed: false, failedReason: EnergISyncFailedReasons.DeviceRestarted } } })
          }
          this.setState({ connectionStatus: { ...this.state.connectionStatus, [connectedDevice]: deviceConnectionStatus } })
        } else if (topic.startsWith(MQTTEvents.DISCONNECTED)) {
          const disConnectedDevice = topic.split('/')[4];
          let deviceConnectionStatus = this.state.connectionStatus[disConnectedDevice] || {};
          deviceConnectionStatus = {
            ...deviceConnectionStatus,
            deviceId,
            connected: data.sessionIdentifer === deviceConnectionStatus.sessionId ? false : deviceConnectionStatus.connected,
            numOfConnections: (deviceConnectionStatus?.numOfConnections || 0) + 1,
            sessionId: data.sessionIdentifier,
          }
          if (this.testingStarted[deviceId]) {
            this.setState({ testingStatus: { ...this.state.testingStatus, [deviceId]: { passed: false, failedReason: EnergISyncFailedReasons.DeviceRestarted } } })
          }
          this.setState({ connectionStatus: { ...this.state.connectionStatus, [disConnectedDevice]: deviceConnectionStatus } })
        } else if (topic.startsWith(MQTTEvents.STATUS_SYNC)) {
          let connectionStatus = { ...this.state.connectionStatus }
          for (const device of data.devices) {
            let deviceConnectionStatus = connectionStatus[device.deviceId] || {};
            deviceConnectionStatus = {
              ...deviceConnectionStatus,
              connected: true,
              numOfConnections: (deviceConnectionStatus.sessionId === device.sessionId ? deviceConnectionStatus.numOfConnections : 0) + 1,
              sessionId: deviceConnectionStatus.sessionId,
            }
            connectionStatus = { ...connectionStatus, [device.deviceId]: deviceConnectionStatus }
          }
          if (data.energISyncTester) {
            let deviceConnectionStatus = connectionStatus[data.energISyncTester.deviceId] || {};
            deviceConnectionStatus = {
              ...deviceConnectionStatus,
              connected: true,
              numOfConnections: (deviceConnectionStatus.sessionId === data.energISyncTester.sessionId ? deviceConnectionStatus.numOfConnections : 0) + 1,
              sessionId: deviceConnectionStatus.sessionId,
            }
            connectionStatus = { ...connectionStatus, [data.energISyncTester.deviceId]: deviceConnectionStatus }
          }
          this.setState({ connectionStatus })
        } else if (topic.startsWith(MQTTEvents.ENERGISYNC_MESSAGE)) {
          const {sid, id} = data;
          const deviceId = topic.split('/')[3];
          this.sentMessages = this.sentMessages.map((message) => {
            if (String(message.deviceId) === String(deviceId) && String(message.switchId) === String(sid) && String(message.commandId) === String(id)) {
              // found the request whose response we have recevied
              return {
                ...message,
                replied: true,
                repliedTimestamp: new Date(),
              }
            }
            return message;
          })
        }
      },
      error: (error) => {

      }
    })
    this.subscriptions[testingStation.deviceId] = s;
  }

  mqttPublish = async (event, payload, testingStation) => {
    const lastIndex = event.lastIndexOf('/');
    const eventName = event.substring(0, lastIndex);
    const deviceId = event.substring(lastIndex + 1)
    if (typeof payload === typeof {}) {
      // if payload if of type object, we send via the pubsub connection
      if (this.testerConnections[testingStation]) {
        return this.testerConnections[testingStation]?.publish({ topics: [event], message: payload })
      } else {
        return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: JSON.stringify(payload) });
      }
    } else {
      return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: String(payload) });
    }
  };

  resetMQTTConnection = (deviceId) => {
    this.subscriptions[deviceId]?.unsubscribe();
    delete this.subscriptions[deviceId];
  }

  onTestingStationConnected = (testingStationDeviceId) => {
    const { devices } = this.state;
    const device = devices.find(d => String(d.testingStation.deviceId) === String(testingStationDeviceId));
    if (!device) {
      return;
    }
    const { testingStation, deviceId } = device;
    setTimeout(() => {
      this.mqttPublish(
        `${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`,
        {
          deviceIds: [deviceId],
          switchPinTester: testingStation.switch_pin_tester,
          fanModuleTester: testingStation.fan_module_tester,
          energISyncTester: testingStation.energisync_tester,
        },
        testingStation.deviceId
      );
    }, 1000);
  };
}

const sleep = (ms = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
});

export default connect(mapStateToProps, {
  passed: TestingActions.hardwareTestPassed,
  failed: TestingActions.failed,
})(TestDevice);
