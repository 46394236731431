import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { createUser, lastEmployeeId } from "../../redux/actions/users";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import { userRoleList } from "../../redux/actions/user";
import { connect } from "react-redux";
class CreateUserPage extends Component {
  state = {
    first_name: "",
    last_name: "",
    phoneNumbers: "",
    emails: "",
    dob: new Date(),
    doj: new Date(),
    employeeId: "",
    password: "",
    gender: "",
    username: "",
    roles: "",
    profile_pic: {},
    id: "",
    aadharNumber:'',
    pancardNumber:''
  };
  async componentDidMount() {
    this.props.userRoleList();
    let id = await lastEmployeeId();
    this.setState({ id: id });
  }
  render() {
    const {
      first_name,
      last_name,
      phoneNumbers,
      emails,
      dob,
      doj,
      employeeId,
      password,
      gender,
      username,
      roles,
      id,
      aadharNumber,
      pancardNumber
    } = this.state;
    let newList = [];
    let currentRoles = roles.split(",");
    newList = this.props.userRoleLists.filter(
      (role) => !currentRoles.includes(role)
    );
    return (
      <div>
        <div className="rounded elevated" style={{ maxWidth: "1200px",overflowY:'scroll',height:'80vh' }}>
          <Form onSubmit={this.handleCreateUser}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={first_name}
                    onChange={this.handleFirstNameChanged}
                    placeholder={"Enter first name"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={last_name}
                    onChange={this.handleLastNameChanged}
                    placeholder={"Enter last name"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Phone numbers</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={phoneNumbers}
                    onChange={this.handlePhoneNumbersChanged}
                    placeholder={"Comma separeted phone numbers"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Emails</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={emails}
                    onChange={this.handleEmailsChanged}
                    placeholder={"Comma separated emails"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    type={"date"}
                    value={dob}
                    onChange={this.handleDOBChanged}
                    placeholder={"Comma separeted phone numbers"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Joining Date</Form.Label>
                  <Form.Control
                    type={"date"}
                    value={doj}
                    onChange={this.handleDOJChanged}
                    placeholder={"Comma separated emails"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Employee ID <span>(Last Id Assign :- {id})</span>
                  </Form.Label>
                  <Form.Control
                    type={"default"}
                    value={employeeId}
                    onChange={this.handleEmployeeIDChanged}
                    placeholder={"Employee ID"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={password}
                    onChange={this.handlePasswordChanged}
                    placeholder={"Password"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={gender}
                    onChange={this.handleGenderChanged}
                    placeholder={"Gender"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type={"default"}
                    value={username}
                    onChange={this.handleUserNameChanged}
                    placeholder={"Username"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Aadhar Card</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='aadharNumber'
                    value={aadharNumber}
                    onChange={(e) => this.handleAaharCard(e)}
                    placeholder={"Enter Aadhar Number"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Pan Card</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='pancardNumber'
                    value={pancardNumber}
                    onChange={(e) => this.handlePanCard(e)}
                    placeholder={"Enter Pan Number"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Roles</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={roles}
                    onChange={this.handleRolesSelected}
                  >
                    <option>Open this select menu</option>
                    {newList.map((e, i) => {
                      return (
                        <option value={e} key={i}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control
                    type={"default"}
                    value={roles}
                    placeholder={"Comma Separated Roles"}
                    onChange={this.handleRolesChanged}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Profile Pic</Form.Label>
                  <Form.Control
                    type={"file"}
                    onChange={this.handleProfilePicChanged}
                    placeholder={"Profile Picture"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button
                  type="button"
                  className="float-end"
                  onClick={this.handleCreateUser}
                >
                  Create User
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  handleCreateUser = async (e) => {
    const { success, message, data } = await createUser(this.state);
    if (success) {
      showSuccessMessage(
        message,
        `Registered employee ${data.user.first_name} with ID ${data.user.employeeId}`
      );
    } else {
      showErrorMessage("Failed", message);
    }
  };

  handleFirstNameChanged = (e) => {
    this.setState({ first_name: e.target.value });
  };

  handleLastNameChanged = (e) => {
    this.setState({ last_name: e.target.value });
  };

  handleEmailsChanged = (e) => {
    this.setState({ emails: e.target.value });
  };
  handleAaharCard = (e) => {
    this.setState({ aadharNumber: e.target.value });
  };
  handlePanCard = (e) => {
    this.setState({ pancardNumber: e.target.value });
  };

  handlePhoneNumbersChanged = (e) => {
    this.setState({ phoneNumbers: e.target.value });
  };

  handleDOBChanged = (e) => {
    this.setState({ dob: e.target.value });
  };

  handleDOJChanged = (e) => {
    this.setState({ doj: e.target.value });
  };

  handleEmployeeIDChanged = (e) => {
    this.setState({ employeeId: e.target.value });
  };

  handlePasswordChanged = (e) => {
    this.setState({ password: e.target.value });
  };

  handleGenderChanged = (e) => {
    this.setState({ gender: e.target.value });
  };

  handleUserNameChanged = (e) => {
    this.setState({ username: e.target.value });
  };

  handleRolesSelected = (e) => {
    this.setState({ roles: `${this.state.roles},${e.target.value}` });
  };

  handleRolesChanged = (e) => {
    this.setState({ roles: e.target.value });
  };

  handleProfilePicChanged = (e) => {
    this.setState({ profile_pic: e.target.files[0] });
  };
}

const mapStateToProps = (state) => ({ userRoleLists: state.usersLists.roles });

export default connect(mapStateToProps, { userRoleList })(
  CreateUserPage
);
