import React, { useState } from "react";
import { Badge, Table, ProgressBar } from "react-bootstrap";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import { EnergISyncTestLogs } from "../../components/EnergISyncTestLogs";
import { energiSyncTestingStatus } from "../../utils/helpers";

const MeterTest = ({ devices, progress, connectionStatus, sentMessages }) => {
  const [expandedDevice, setExpandedDevice] = useState('');
  return (
    <div>
      <TestStepsHeader
        icon={Images.EnergISyncHardwareTesting.Meter}
        title={"Meter Testing"}
      />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Device ID</th>
            <th>Φ</th>
            <th>Testing Station</th>
            <th>Control Meter</th>
            <th>Device Status</th>
            <th>Master Status</th>
            <th>Test Progress</th>
            <th>Testing Status</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(progress).map((applianceId, index) => {
              const [deviceId, phaseId] = applianceId.split('_');
              const device = devices.find(d => d.deviceId === deviceId);
              if (!device || device?.deviceId !== deviceId) {
                return null;
              }
              const deviceConnected = connectionStatus[device?.deviceId]?.connected;
              const testerConnected = connectionStatus[device?.testingStation?.energisync_tester]?.connected;
              const { passed, failedReason } = energiSyncTestingStatus(deviceId, sentMessages.filter(sm => String(sm.deviceId) === String(deviceId) && String(sm.switchId) === String(phaseId)));
              return (
                <tr key={applianceId} onClick={() => {
                  if (expandedDevice !== applianceId) {
                    setExpandedDevice(applianceId)
                  } else {
                    setExpandedDevice('');
                  }
                }}>
                  <td>{index + 1}</td>
                  <td>{device.deviceId}</td>
                  <td>{Number(phaseId) + 1}</td>
                  <td>{device.testingStation.name}</td>
                  <td>{device.testingStation.energisync_tester}</td>
                  <td>
                    <h5>
                      <Badge bg={deviceConnected ? "success" : "danger"}>
                        {deviceConnected ? "Online" : "Offline"}
                      </Badge>
                    </h5>
                  </td>
                  <td>
                    <h5>
                      <Badge bg={testerConnected ? "success" : "danger"}>
                        {testerConnected ? "Online" : "Offline"}
                      </Badge>
                    </h5>
                  </td>
                  <td>
                    <ProgressBar animated striped now={progress[applianceId]} />
                  </td>
                  <td>
                    <h5>
                      <Badge bg={passed ? "success" : "danger"}>
                        {passed ? "Passing" : `Failed - ${failedReason}`}
                      </Badge>
                    </h5>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      {expandedDevice !== '' && (
        <EnergISyncTestLogs
          logs={sentMessages.filter(msg => String(msg.deviceId) === String(expandedDevice.split('_')[0]) && String(msg.switchId) === String(expandedDevice.split('_')[1]))}
          deviceId={expandedDevice}
        />
      )}
    </div>
  );
};


export default MeterTest;
