import React, { Component } from 'react';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import DeviceProblemsList from '../components/DeviceProblemsList';
import TestStepsHeader from '../components/TestStepsHeader';
import { TestingType } from '../config/constants';
import Images from '../config/images';
import TestingActions from '../redux/actions/testing';
import { showErrorMessage, showInfoMessage } from '../utils/helpers';
import APIs from '../utils/apis';
class BasicTest extends Component {
	state = {
		deviceId: '',
		message: '',
		device: {},
	}
	processing = false;

	render() {
		const { deviceId, message, device } = this.state;
		return (
			<Row className={'g-0'}>
				<Col lg={4}>
					<div className={'rounded elevated'}>
					<TestStepsHeader
							icon={Images.BasicTesting.Test}
							title={"Basic Testing"}
						/>
						<Form.Group>
							<Form.Label>
								Enter Device ID
							</Form.Label>
							<Form.Control
								value={deviceId}
								onChange={this.handleDeviceIDChanged}
								placeholder={'Device ID'}
							/>
						</Form.Group>
						<br />
						<Button onClick={this.handleGetDeviceDetails}>
							Get Details
						</Button>
						<br />
						<br />
						{device && device.discarded === true && (
							<center>
								<h4>
									<Badge bg={"danger"}>Has been marked as discarded</Badge>
								</h4>
							</center>
						)}
						{device && device.basic_tested === true && (
							<center>
								<h4>
									<Badge bg={"success"}>Has already passed basic test</Badge>
								</h4>
							</center>
						)}
						<Form.Group>
							<Form.Label>Add a message for logs:</Form.Label>
							<Form.Control
								as={"textarea"}
								rows={5}
								value={message}
								placeholder={"Tester message"}
								onChange={this.handleMessageChanged}
							/>
						</Form.Group>

						<div style={{ marginTop: 10 }}>
							<Row>
								<Col lg={3} />
								<Col>
									<Row style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
										<Col />
										<Col>
											<Button
												variant={"danger"}
												onClick={this.onFailed}
												disabled={String(device.deviceId) !== String(deviceId)}
												active={String(device.deviceId) === String(deviceId)}
											>
												Failed
											</Button>
										</Col>
										<Col>
											<Button
												variant={"primary"}
												onClick={this.onPassed}
												disabled={String(device.deviceId) !== String(deviceId)}
												active={String(device.deviceId) === String(deviceId)}
											>
												Passed
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				{device.deviceId && (
					<Col lg={4}>
						<div className={"rounded elevated"}>
							<DeviceProblemsList
								device={device}
								onResolve={this.onResolveProblem}
								type={TestingType.Basic}
							/>
						</div>
					</Col>
				)}

			</Row>
		)
	}

	handleGetDeviceDetails = async () => {
		const { success, data, message } = await TestingActions.register(this.state.deviceId);
		if (!success) {
			showErrorMessage("Failed to get device details", message)
			return;
		}
		showInfoMessage("Got device details", message);
		this.setState({ device: data.device });
	}

	onResolveProblem = async (log, index) => {
		if (this.processing) return;
		this.processing = true;
		if (log.passed || log.resolved) {
			// already a resolved issue; return;
		}
		const { device } = this.state;
		const { success, message, data } = await this.props.resolveFailure({
			deviceId: device.deviceId,
			index,
			message: "",
		});
		if (!success) {
			showErrorMessage("Could not reolve problem", message)
		} else {
			this.setState({ device: data.device });
		}
		this.processing = false;
	};

	onPassed = async () => {
		if (this.processing) return;
		this.processing = true;
		const { device } = this.state;
		if (device.discarded) {
			this.processing = false;
			return showErrorMessage("Can not process device", "A device marked as discarded can not be processed further")
		}
		const deviceOnlineResponse = await APIs.isDeviceOnline(device.deviceId);
		if (!deviceOnlineResponse.success) {
			this.processing = false;
			return showErrorMessage("Can not process device", "Device connectiviy details not found")
		}
		if (!deviceOnlineResponse.data.isOnline) {
			this.processing = false;
			return showErrorMessage("Can not process device", "Device is offline");
		}
		const payload = {
			deviceId: device.deviceId,
			testing_station: '',
			testing_type: '',
			type: TestingType.Basic,
			message: this.state.message.trim(),
		};

		const result = await this.props.basicPassed(payload);
		if (!result.success) {
			showErrorMessage("Could not pass device", result.message);
		} else {
			this.setState({ deviceId: '', device: {}, message: '' });
		}
		this.processing = false;
	};

	onFailed = async () => {
		if (this.processing) return;
		this.processing = true;
		const { device } = this.state;
		if (device.discarded) {
			this.processing = false;
			return showErrorMessage("Can not process device", "A device marked as discarded can not be processed further")
		}
		let reason = "Custom Reason";
		const payload = {
			deviceId: device.deviceId,
			testing_station: '',
			switch_pin_tester: '',
			testing_type: '',
			type: TestingType.Basic,
			reason,
			message: this.state.message,
		};
		await this.props.failed(payload);
		this.setState({ deviceId: '', device: {}, message: '' })
		this.processing = false;
	};

	handleMessageChanged = (e) => {
		this.setState({ message: e.target.value });
	}
	
	handleDeviceIDChanged = (e) => {
		this.setState({ deviceId: e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, '')});
	}

}

const mapStateToProps = state => ({});


export default connect(mapStateToProps, { resolveFailure: TestingActions.resolveFailure, failed: TestingActions.failed, basicPassed: TestingActions.basicTestPassed, })(BasicTest);